<template>
  <div id="wizardCategory">
    <b-row class="mb-5">
      <b-col
        md="10"
        class=""
      >
        <h1>{{ $t('category.createCat') }}</h1>
        <h5 class="text-primary">
          {{ $t('crearCat') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          variant="danger"
          @click="goBack()"
        >
          {{
            $t("dataGeneric.goBack")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.save')"
        :back-button-text="$t('dataGeneric.previous')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- Información del contenido tab -->
        <tab-content
          :title="$t('category.info')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("category.info") }}
                </h5>
                <small class="text-muted">
                  {{ $t("category.small") }}
                </small>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group :label="$t('dataGeneric.name') + ' *'">
                  <validation-provider
                    v-slot="validationContext"
                    name="name"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('category.name')"
                      maxlength="150"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('contents.categoryType')"
                >
                  <b-form-select
                    v-model="typeCategory"
                    class="mr-1"
                  >
                    <b-form-select-option
                      v-for="typ in [
                        { value: 'NOR', text: $t('NOR') }, { value: 'VEN', text: $t('Vendedor') },]"
                      :key="typ.value"
                      :value="typ.value"
                    >
                      {{
                        typ.text
                      }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('contents.template')"
                >
                  <b-form-select
                    v-model="templateCategorySelected"
                    :options="templateCategory"
                    class="mr-1"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="12"
                sm="12"
              >
                <b-form-group :label="$t('dataGeneric.description')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="description"
                  >
                    <b-form-textarea
                      v-model="description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('category.description')"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('externalRef')">
                  <b-form-input
                    id="reference"
                    v-model="reference"
                    maxlength="180"
                    :placeholder="$t('externalRef')"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Final">
                  <b-form-checkbox
                    id="categoriaFinal"
                    v-model="isFinal"
                    name="categoriaFinal"
                    :switch="true"
                  >
                    {{ $t('thisCategoryOnlyHaveProductsRes') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col />
              <b-col md="6">
                <b-form-group :label="$t('vendorCat')">
                  <div @click="$refs['vend-modal'].show()">
                    <b-form-input
                      id="category"
                      v-model="vendorName"
                      autocomplete="new-password"
                      :placeholder="$t('vendorCat')"
                      disabled
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                md="1"
                class="align-items-center align-self-center mt-1"
              >
                <b-button
                  variant="info"
                  @click="$refs['vend-modal'].show()"
                >
                  {{ $t('Select') }}
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- Archivos del contenido  tab -->
        <tab-content
          :title="$t('category.categoryFiles')"
          :before-change="validationFormFile"
        >
          <validation-observer
            ref="fileRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t('Images') }}
                </h5>
                <small class="text-muted">{{ $t("category.image") }}</small>
              </b-col>
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('resources.originMedia')"
                >
                  <b-form-select
                    v-model="mediaLocation"
                    class="mr-1"
                    :options="mediaOriginOptions"
                  />
                </b-form-group>
                <div>
                  <!-- FILA TV/WEB -->
                  <b-form-group class="m-1">
                    <label><strong>TV/Web</strong></label>
                    <b-row style="justify-content: space-around;;">
                      <!-- TARJETA -->
                      <image-element-vue
                        :image-origin="mediaLocation == 'BUK' ? imageCategory : imageCategoryUrlSelected"
                        :media-origin-selected="mediaLocation"
                        :image-type="'imageCategory'"
                        :label="$t('contents.imgInternal')"
                        @saveImage="loadImage"
                      />
                      <!-- FONDO NORMAL -->
                      <image-element-vue
                        :image-origin="mediaLocation == 'BUK' ? backgroundCategory : backgroundCategoryURL"
                        :media-origin-selected="mediaLocation"
                        :label-info="$t('infoImage.background')"
                        :image-type="'backgroundCategory'"
                        :label="$t('contents.bckgInternal') + ' ' + $t('precontent')"
                        @saveImage="loadImage"
                      />
                      <image-element-vue
                        v-if="templateCategorySelected == 'BCK' || templateCategorySelected == 'INF'"
                        :image-origin="mediaLocation == 'BUK' ? backgroundRoot : backgroundRootURL"
                        :media-origin-selected="mediaLocation"
                        :image-type="'backgroundRoot'"
                        :label="$t('backList')"
                        @saveImage="loadImage"
                      />
                      <b-col
                        v-else
                        cols="3"
                      />
                    </b-row>
                  </b-form-group>
                  <!-- FILA MÓVIL -->
                  <b-form-group class="m-1">
                    <label><strong>{{ $t('Movil') }}</strong></label>
                    <b-row style="justify-content: space-around;;">
                      <!-- TARJETA MÓVIL -->
                      <image-element-vue
                        :image-origin="mediaLocation == 'BUK' ? mobileImageCategory : mobileImageCategoryURL"
                        :media-origin-selected="mediaLocation"
                        :image-type="'mobileImageCategory'"
                        :label="$t('contents.mobileImgInternal')"
                        @saveImage="loadImage"
                      />
                      <!-- FONDO MÓVIL NORMAL -->
                      <image-element-vue
                        :image-origin="mediaLocation == 'BUK' ? backgroundMobileCategory : backgroundMobileCategoryURL"
                        :media-origin-selected="mediaLocation"
                        :image-type="'backgroundMobileCategory'"
                        :label-info="$t('infoImage.backgroundMobile')"
                        :label="$t('backPre')"
                        @saveImage="loadImage"
                      />

                      <image-element-vue
                        v-if="templateCategorySelected == 'BCK' || templateCategorySelected == 'INF'"
                        :image-origin="mediaLocation == 'BUK' ? backgroundRootMobile : backgroundRootMobileURL"
                        :media-origin-selected="mediaLocation"
                        :image-type="'backgroundRootMobile'"
                        :label="$t('backListMov')"
                        @saveImage="loadImage"
                      />
                      <b-col
                        v-else
                        cols="3"
                      />
                    </b-row>
                  </b-form-group>
                  <!-- FILA RECURSOS VARIOS -->
                  <b-form-group class="m-1">
                    <label><strong>{{ $t('otherRes') }}</strong></label>
                    <b-row style="justify-content: space-around;;">
                      <image-element-vue
                        :image-origin="mediaLocation == 'BUK' ? altImageCategory : altImageCategoryURL"
                        :media-origin-selected="mediaLocation"
                        :label-info="$t('infoImage.alternative')"
                        :image-type="'altImageCategory'"
                        :label="$t('editContent.image')"
                        @saveImage="loadImage"
                      />
                      <b-col cols="3" />
                      <b-col cols="3" />
                    </b-row>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content title="SEO">
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                SEO
              </h5>
              <small class="text-muted">{{ $t('fieldSeo') }}</small>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('title')">
                <b-form-input
                  id="title"
                  v-model="seoTitle"
                  maxlength="80"
                  :placeholder="$t('title')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('dataGeneric.description')">
                <b-form-input
                  id="description"
                  v-model="seoDescription"
                  maxlength="180"
                  :placeholder="$t('dataGeneric.description')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content
          :title="$t('contents.categoryDesign')"
          :before-change="validationFormDesign"
        >
          <validation-observer
            ref="designRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("contents.categoryDesign") }}
                </h5>

                <small class="text-muted">{{ $t('designCards') }}</small>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <span>{{ $t("contents.categoryCard") }}</span>

                <b-form-group
                  class="
                    mt-2
                              mb-2
                              mr-1
                              embed-responsive embed-responsive-16by9
                            "
                >
                  <div
                    id="imageCatTV"
                    class="base-card-input embed-responsive-item"
                    @click="showModalCard('imageCatTV')"
                  >
                    <span
                      class="placeholder"
                      :style="cardSelected != null
                        ? `background: url('${cardSelected.sampleImage}')center/contain no-repeat;`
                        : ''"
                    >
                      <feather-icon
                        v-if="cardSelected == null"
                        icon="LayoutIcon"
                        size="80"
                      />
                    </span>
                  </div>
                </b-form-group>
                <span>{{
                  cardSelected != null ? cardSelected.name : $t('noSelect')
                }}</span>
              </b-col>
              <b-col md="4">
                <span>{{ $t("contents.smartphoneCategoryCard") }}</span>

                <b-form-group
                  class="
                    mt-2
                              mb-2
                              mr-1
                              embed-responsive embed-responsive-16by9
                            "
                >
                  <div
                    id="imageContTV"
                    class="base-card-input embed-responsive-item"
                    @click="showModalCard('imageContTV')"
                  >
                    <span
                      class="placeholder"
                      :style="cardMobileSelected != null
                        ? `background: url('${cardMobileSelected.sampleImage}')center/contain no-repeat;`
                        : ''"
                    >
                      <feather-icon
                        v-if="cardMobileSelected == null"
                        icon="LayoutIcon"
                        size="80"
                      />
                    </span>
                  </div>
                </b-form-group>
                <span>{{
                  cardMobileSelected != null
                    ? cardMobileSelected.name
                    : $t('noSelect')
                }}</span>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>

    <b-modal
      ref="modalCard"
      size="xl"
      hide-footer
      :title="$t('modal.card')"
    >
      <div class="d-block text-center">
        <card-selector-modal @id="SelectCardId" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>

    <b-modal
      ref="vend-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <vendor-selector @vendor="selectVendor" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>

import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import {
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'
import { messageError } from '@/store/functions'

// import { codeIcon } from './code'
import { getUserData } from '@/auth/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'
import CardSelectorModal from '@/views/common/CardSelectorModal.vue'
import VendorSelector from '@/views/common/VendorSelectorModal.vue'

export default {
  components: {
    BOverlay,
    BFormSelectOption,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    ImageElementVue,
    FormWizard,
    TabContent,
    BFormSelect,
    BFormTextarea,
    VendorSelector,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    CardSelectorModal,
  },
  data() {
    return {
      backgroundRoot: null,
      backgroundRootMobile: null,
      backgroundRootURL: '',
      backgroundRootMobileURL: '',
      seoTitle: null,
      seoDescription: null,
      userData: getUserData(),
      mobileImageCategorySrc: null,
      reference: '',
      imageCategorySrc: null,
      backgroundCategorySrc: null,
      titleImageCategorySrc: null,
      trailerCategorySrc: null,
      imageCategoryUrlSelected: null,
      mobileImageCategoryURL: null,
      backgroundCategoryURL: null,
      titleImageCategoryURL: null,
      backgroundMobileCategoryURL: null,
      trailerCategoryURL: null,
      imageCategory: null,
      imageCategorySelectedPlaceholder: '',
      templateCategorySelected: null,
      templateCategory: [
        { value: null, text: this.$t('designTypes.templ') },
        { value: 'LAT', text: this.$t('designTypes.side') },
        { value: 'BCK', text: 'Background' },
        { value: 'INF', text: this.$t('designTypes.backInfo') },
      ],
      mobileImageCategory: null,
      mobileImageCategoryPlaceholder: '',
      backgroundCategory: null,
      backgroundCategoryPlaceholder: '',
      altImageCategory: null,
      altImageCategorySrc: null,
      altImageCategoryURL: '',

      tipo: '',
      imageClicked: '',
      mediaLocation: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      isFinal: true,
      name: '',
      description: '',
      inputs: null,
      required,

      cardSelected: null,
      cardMobileSelected: null,
      blurEffectStatus: false,
      typeCategory: 'NOR',
      vendorId: null,
      vendorName: null,
      show: false,
      typeCard: null,
      final: false,
      backgroundMobileCategory: null,
      backgroundMobileCategorySrc: null,

    }
  },
  mounted() {
  },
  methods: {
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('purchases.nameFormValid')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },

    hideModal() {
      this.$refs['vend-modal'].hide()
      this.$refs.modalCard.hide()
    },
    showModalCard(type) {
      this.$refs.modalCard.show()
      this.typeCard = type
    },
    SelectCardId(file) {
      switch (this.typeCard) {
        case 'imageCatTV':
          this.cardSelected = file
          break
        case 'imageContTV':
          this.cardMobileSelected = file
          break
        default:
          break
      }
      this.hideModal()
    },

    formSubmitted() {
      this.show = true
      let order = 0
      order = this.$route.params.order != null ? this.$route.params.order : 0
      const idParent = this.$route.params.id != null ? this.$route.params.id : null

      const data = new FormData()
      let client = this.userData.profile.client.id
      const [, split] = atob(this.userData.profile.client.id).split(':')
      client = split || client

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
        mutation (
          $name: String!,
          $description: String,
          $type: CategoryShopInputType,
          $isFinal: Boolean,
          $card: ID,$vendor: ID,
          $cardMobile: ID,
          $client: ID!,
          $mediaLocation: CategoryMediaLocationInput,
          $reference: String,
          $imageUrl: String,
          $imageMobileUrl: String,
          $templateCategory: CategoryTemplateCategoryInput,
          $backgroundUrl: String,
          $backgroundRootUrl: String,
          $backgroundRootMobileUrl: String,
          $backgroundMobileUrl: String,
          $seoTitle: String,
          $seoDescription: String,     
          $alternativeImageUrl: String
        ){
          createCategoryShop(input:{
            name: $name,
            client: $client,
            description: $description,
            type: $type,
            isFinal: $isFinal,
            alternativeImageUrl:  $alternativeImageUrl,
            reference: $reference,
            card: $card,
            seoTitle: $seoTitle,
            templateCategory: $templateCategory,
            seoDescription: $seoDescription,
            vendor: $vendor,
            order: ${order},
            cardMobile: $cardMobile,
            mediaLocation: $mediaLocation,
            imageUrl: $imageUrl,
            imageMobileUrl: $imageMobileUrl,
            backgroundUrl: $backgroundUrl,
            backgroundMobileUrl: $backgroundMobileUrl,
            backgroundRootUrl: $backgroundRootUrl,
            backgroundRootMobileUrl: $backgroundRootMobileUrl,
            ${idParent != null ? `parent: "${idParent}"` : ''}
          }){
          shop {
            name
            image
            imageUrl
            imageMobile
            imageMobileUrl
          }
        }
      }
    `
      const variables = {
        name: this.name,
        vendor: this.vendorId != null ? this.vendorId : null,
        client,
        type: this.typeCategory,
        description: this.description,
        reference: this.reference,
        isFinal: this.isFinal,
        mediaLocation: this.mediaLocation,
        card: this.cardSelected != null ? this.cardSelected.id : null,
        cardMobile:
          this.cardMobileSelected != null ? this.cardMobileSelected.id : null,
        imageUrl: this.imageCategoryUrlSelected,
        imageMobileUrl: this.mobileImageCategoryURL,
        seoTitle: this.seoTitle,
        templateCategory: this.templateCategorySelected,
        seoDescription: this.seoDescription,
        backgroundUrl: this.backgroundCategoryURL,
        backgroundMobileUrl: this.backgroundMobileCategoryURL,
        alternativeImageUrl: this.altImageCategoryURL,
        backgroundRootUrl: this.backgroundRootUrl,
        backgroundRootMobileUrl: this.backgroundRootMobileUrl,

      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      data.append('image', this.imageCategory)
      data.append('image_mobile', this.mobileImageCategory)
      data.append('background', this.backgroundCategory)
      data.append('background_mobile', this.backgroundMobileCategory)
      data.append('alternative_image', this.altImageCategory)
      data.append('background_root', this.backgroundRoot)
      data.append('background_root_mobile', this.backgroundRootMobile)

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)

          this.show = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('success'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          if (res.status === 200) {
            if (idParent != null) {
              this.$router.push({
                name: 'subCategory-shop',
                params: { code: idParent },
              })
            } else {
              this.$router.push({ name: 'category-shop' })
            }
          }
        })
        .catch(res => {
          console.log(res)
        })
    },

    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormFile() {
      return new Promise((resolve, reject) => {
        this.$refs.fileRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormDesign() {
      return new Promise((resolve, reject) => {
        this.$refs.designRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    selectVendor(data) {
      this.hideModal()
      this.vendorId = data.id
      this.vendorName = data.vendorName
    },
    loadImage(item) {
      const { data, type } = item
      switch (type) {
        case 'imageCategory':
          if (this.mediaLocation === 'BUK') this.imageCategory = data
          else this.imageCategoryUrlSelected = data

          break
        case 'backgroundCategory':
          if (this.mediaLocation === 'BUK') this.backgroundCategory = data
          else this.backgroundCategoryURL = data
          break
        case 'mobileImageCategory':
          if (this.mediaLocation === 'BUK') this.mobileImageCategory = data
          else this.mobileImageCategoryURL = data
          break
        case 'backgroundMobileCategory':

          if (this.mediaLocation === 'BUK') this.backgroundMobileCategory = data
          else this.backgroundMobileCategoryURL = data
          break
        case 'altImageCategory':
          if (this.mediaLocation === 'BUK') this.altImageCategory = data
          else this.altImageCategoryURL = data
          break
        case 'backgroundRoot':
          if (this.mediaLocation === 'BUK') this.backgroundRoot = data
          else this.backgroundRootURL = data
          break
        case 'backgroundRootMobile':
          if (this.mediaLocation === 'BUK') this.backgroundRootMobile = data
          else this.backgroundRootMobileURL = data
          break
        default:
          break
      }
    },

    goBack() {
      window.history.back()
    },

  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#wizardCategory .base-card-input {
  display: block;
  /*  width: 200px;
  height: 200px; */
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#wizardCategory .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#wizardCategory .placeholder:hover {
  background: #e0e0e0;
}

#wizardCategory .file-input {
  display: none;
}
</style>
